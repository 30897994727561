import React, { useState, useRef, useEffect } from "react";

import { graphql } from "gatsby"
import { motion, useViewportScroll, useTransform } from "framer-motion";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact";
import Office from "../components/office";
import ProjectHero from "../components/projectHero";
import IntroductionHero from "../components/introductionHero";
import Img from "gatsby-image"


const NonStretchedImage = props => {
    let normalizedProps = props
    if (props.fluid && props.fluid.presentationWidth) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                maxWidth: props.fluid.presentationWidth,
                margin: "0 auto", // Used to center the image
            },
        }
    }

    return <Img {...normalizedProps} />
}

const CaseWrapper = ({ data, ...meta }) => {

    const [elementTop, setElementTop] = useState(0);
    const ref = useRef(null);

    const { scrollY } = useViewportScroll();
    const y = useTransform(scrollY, [elementTop, elementTop + 6], [0, -1], {
        clamp: false,
    });

    useEffect(() => {
        const element = ref.current;
        setElementTop(element.offsetTop);
    }, [ref]);

    return (
        <div className={`o-case o-case--${meta.uid}`} ref={ref}>

            <motion.div className={'o-case__img'} style={{ y }}>
                <NonStretchedImage fluid={data.thumbnail.localFile.childImageSharp.fluid} alt={ data.client.text }  />
            </motion.div>
            <motion.div className={`o-case__wrapper`} data-title={ data.client.text }>
            <h2 className={`o-case__title`}>
                { data.client.text }
            </h2>
            <div className="o-case__body">
                <p className={'o-case__description'}>
                    { data.description.text }
                </p>
                {data.web_link && false &&
                    <a href={data.web_link.url} className={`u-link o-case__link`} rel={`nofollow`}>view project</a>
                }
                <h6 className={'o-case__subtitle u-h6'}>Our solutions</h6>
                <ul className={'o-case__solutions'}>
                {data.solutions.map(i =>
                    <li key={i.solutions_item.text}>{i.solutions_item.text}</li>
                )}
                </ul>
            </div>
            </motion.div>
        </div>
    );
};

const IndexPage = ({ data: { allPrismicCase } }) => (
  <Layout>
    <SEO title="Full-service Development Partner" description={"The App Office is a full-service development partner, with a focus on frontend development."} />

      <IntroductionHero />
      <ProjectHero />

      <div className={'o-section [ c-cases ]'}>
          {allPrismicCase.edges.map(i =>
              <CaseWrapper key={i.node.uid} {...i.node} />
          )}
      </div>
      <Contact />
      <Office/>

  </Layout>
)

export default IndexPage

export const query = graphql`
{
  allPrismicCase(sort: {fields: data___publication_date, order: DESC}) {
    edges {
      node {
        uid
        data {
          client {
            text
          }
          meta {
            text
          }
          description {
            text
          }
          thumbnail {
            url
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 980) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  presentationWidth
                }
              }
            }
          }
          web_link {
            url
          }
          solutions {
            solutions_item {
              text
            }
          }
        }
      }
    }
  }
}
`
