import React, {useEffect, useRef, useState} from "react";
import {graphql, StaticQuery} from "gatsby"
import ScrollIcon from "../images/scroll.inline.svg";
import IntroVideo from "../assets/animation.mp4";

const IntroductionHero =  ({ data }) => {
    const [playOnScroll, setPlayOnScroll] = useState(false);

    const content = data.prismicHero.data;
    const videoRef = useRef(null);

    useEffect(() => {
        if (!playOnScroll) { return; }
        const elmHeight = videoRef.current.getBoundingClientRect().height;
        const handleScroll = (e) => {
            if (!playOnScroll || !videoRef.current ) { return; }
            const { duration } =  videoRef.current;
            const scroll = (document.documentElement.scrollTop || document.body.scrollTop);
            // Scroll percentage based on 'window' height
            const scrollPercent = scroll / (videoRef.current.offsetTop + elmHeight * 0.5);
            videoRef.current.currentTime = Math.min(duration, Math.abs(Math.min(duration * scrollPercent, duration) - duration));
        };

        document.removeEventListener('scroll', handleScroll);
        document.addEventListener('scroll', handleScroll);

        return function() {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [playOnScroll, videoRef]);

    useEffect(() => {
    }, [videoRef]);

    function onEnded() {
        if (playOnScroll) { return; }
        setPlayOnScroll(true);
    }

    return (
        <div className={'o-section [ c-intro ]'}>
            <div className="c-intro__wrapper">
                <header dangerouslySetInnerHTML={{__html: content.title.html}}/>
                <div className={`c-intro__body`} dangerouslySetInnerHTML={{__html: content.description.html}}/>
                <ScrollIcon className={`o-icon__scroll`}/>
            </div>
            <video className={`c-intro__video`} onEnded={onEnded} autoPlay={!playOnScroll} width="100%"
                   preload={"auto"} muted playsInline ref={videoRef}>
                <source src={IntroVideo} type="video/mp4"/>
            </video>
        </div>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
          query {
            prismicHero(uid: {eq: "introduction"}) {
            data {
              description {
                html
                text
              }
              leading_title
              title {
                html
                text
              }
            }
          }
      }
    `}
        render={data => <IntroductionHero data={data} {...props} />}
    />
)
